import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import React, { ComponentProps, useState } from 'react'
import {
  ArrowContainer,
  ReviewsSectionContent,
  ReviewsSectionRoot,
  Selector,
  SelectorsContainer,
  StampContainer,
  Viewer,
  ViewerContainer,
} from './ReviewsSection.styles'
import isologo from '../../../../../public/assets/isologo.svg'

export type ReviewItem = {
  name: string
  message: string
}

type ReviewsSectionOwnProps = ComponentProps<typeof ReviewsSectionRoot>
export type ReviewsSectionProps = ReviewsSectionOwnProps & {
  reviews: ReviewItem[]
  cta: string
  title?: string
}

export const ReviewsSection = ({ reviews, title, cta }: ReviewsSectionProps) => {
  const { t } = useTranslation()

  const [activeReview, setActiveReview] = useState(0)

  const previousReview = () => {
    setActiveReview(activeReview > 0 ? activeReview - 1 : reviews.length - 1)
  }

  const nextReview = () => {
    setActiveReview(activeReview === reviews.length - 1 ? 0 : activeReview + 1)
  }

  return (
    <ReviewsSectionRoot>
      <ReviewsSectionContent>
        <Stack gap="11" direction="column">
          {title && (
            <Text as="h2" size="h1" weight="medium" align="center">
              {title}
            </Text>
          )}
          <Stack gap="5" direction="column">
            <Stack gap="3" direction="column">
              <ViewerContainer>
                <ArrowContainer className="left">
                  <button onClick={previousReview} aria-label={t('home:reviews.previous')}>
                    <Icon color="secondary-500" size="10" name="angle-left" />
                  </button>
                </ArrowContainer>
                <Viewer>
                  <StampContainer>
                    <Image src={isologo} alt="" layout="fill" loading="lazy" />
                  </StampContainer>
                  <Stack gap="3" direction="column">
                    <Stack direction="column">
                      <Text weight="medium" size="headline" align="center">
                        {t(reviews[activeReview].name)}
                      </Text>
                    </Stack>
                    <Text as="p" align="center">
                      “{t(reviews[activeReview].message)}”
                    </Text>
                  </Stack>
                </Viewer>
                <ArrowContainer className="right">
                  <button onClick={nextReview} aria-label={t('home:reviews.next')}>
                    <Icon color="secondary-500" size="10" name="angle-right" />
                  </button>
                </ArrowContainer>
              </ViewerContainer>
              <SelectorsContainer gap="2">
                {reviews.map((_, index) => (
                  <Selector
                    key={index}
                    active={index === activeReview}
                    onClick={() => setActiveReview(index)}
                    aria-label={t('home:reviews.review', { index: index + 1 })}
                  />
                ))}
              </SelectorsContainer>
            </Stack>
            <Box>
              <Button as="a" href="/meal-plan">
                {cta}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </ReviewsSectionContent>
    </ReviewsSectionRoot>
  )
}

ReviewsSection.displayName = 'ReviewsSection'
