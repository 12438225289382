import { IllustratedList, IllustratedListItems } from 'components/IllustratedList'
import benefitHeart from '../../../../public/assets/illustration-heart.svg'
import benefitPaw from '../../../../public/assets/illustration-paw.svg'
import benefitChop from '../../../../public/assets/illustration-chop.svg'
import benefitDelivery from '../../../../public/assets/illustration-delivery.svg'
import { useTranslation } from 'hooks/useTranslation'

export const Benefits = () => {
  const { t } = useTranslation()

  const BENEFITS: IllustratedListItems[] = [
    {
      image: benefitHeart,
      title: t('home:benefits.balanced'),
      loadType: 'eager',
    },
    {
      image: benefitPaw,
      title: t('home:benefits.nutritionist'),
      loadType: 'eager',
    },
    {
      image: benefitChop,
      title: t('home:benefits.usda'),
      loadType: 'eager',
    },
    {
      image: benefitDelivery,
      title: t('home:benefits.shipment'),
      loadType: 'eager',
    },
  ]

  return (
    <section>
      <IllustratedList items={BENEFITS} />
    </section>
  )
}
