import React, { ComponentProps } from 'react'
import { IconName } from 'components/Icon/iconNames'
import { Icon } from 'components/Icon/'
import { BulletedListRoot, Item } from './BulletedList.styles'
import { ColorTokens } from 'stitches.config'
import Trans from 'next-translate/Trans'
import { Text } from 'components/Text'

type BulletedListOwnProps = ComponentProps<typeof BulletedListRoot>

export type BulletedListItems = {
  name: string
  icon: IconName
}
export type BulletedListProps = BulletedListOwnProps & {
  items: BulletedListItems[]
  columnCount?: number
  iconColor?: ColorTokens
}

export const BulletedList = ({ items, iconColor, columnCount = 1 }: BulletedListProps) => {
  return (
    <BulletedListRoot role="list" css={{ '@bp2': { columnCount: columnCount } }}>
      {items.map(({ name, icon }, index) => (
        <Item key={index} css={columnCount === 1 ? { marginBottom: '$2' } : undefined}>
          <Icon
            name={icon as IconName}
            color={iconColor ? iconColor : undefined}
            size={{ '@initial': '4', '@bp1': '5' }}
          />
          <Text>
            <Trans i18nKey={name} components={{ bold: <Text weight="medium" /> }} />
          </Text>
        </Item>
      ))}
    </BulletedListRoot>
  )
}

BulletedList.displayName = 'BulletedList'
