import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import Image from 'next/image'
import { styled } from 'stitches.config'

export const Illustration = styled(Image, {
  size: '152px',

  '@bp2': {
    size: '128px',
  },

  '@bp3': {
    size: '152px',
  },
})

export const Title = styled(Text, {
  maxWidth: '210px',
  color: '$primary-800',
})

export const Description = styled(Text, {
  maxWidth: '250px',
  color: '$primary-800',
})

export const IllustratedListRoot = styled('div', {
  maxWidth: '100%',
  margin: 'auto',

  [`& ${Stack}`]: {
    alignItems: 'center',
  },
  [`& li > ${Stack}`]: {
    display: 'flex',
    flexDirection: 'column',

    textAlign: 'center',
  },
  '@bp2': {
    justifyContent: 'center',

    '&&': {
      alignItems: 'flex-start',
    },
  },
})
