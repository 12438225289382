import React, { ComponentProps } from 'react'
import { Stack } from 'components/Stack'
import { IllustratedListRoot, Illustration, Title, Description } from './IllustratedList.styles'

type IllustratedListOwnProps = ComponentProps<typeof IllustratedListRoot>

export type IllustratedListItems = {
  image: string
  title: string
  description?: string
  loadType?: 'eager' | 'lazy' | undefined
}

export type IllustratedListProps = IllustratedListOwnProps & {
  items: IllustratedListItems[]
}

export const IllustratedList = ({ items }: IllustratedListProps) => {
  return (
    <IllustratedListRoot>
      <Stack
        justifyContent={{ '@initial': 'flex-start', '@bp2': 'center' }}
        gap="5"
        direction={{ '@initial': 'column', '@bp2': 'row' }}
        as="ul"
        role="list"
      >
        {items.map(({ image, title, description, loadType }, index) => (
          <li key={index}>
            <Stack gap="3" direction="column" css={{ alignItems: 'center' }}>
              <Illustration src={image} alt="" loading={loadType} />
              <Stack direction="column">
                <Title size="headline" weight="medium" align="center">
                  {title}
                </Title>
                {description && (
                  <Description as="p" size="body" weight="regular" align="center">
                    {description}
                  </Description>
                )}
              </Stack>
            </Stack>
          </li>
        ))}
      </Stack>
    </IllustratedListRoot>
  )
}

IllustratedList.displayName = 'IllustratedList'
