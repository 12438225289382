import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const Claim = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$2',
  px: '$3',
  color: '$primary-800',
  margin: 'auto',

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: 0,
    px: '$15',

    '&': {
      [`${Text}:first-child, ${Text}:last-child`]: {
        flex: 1,
      },
      [`${Text}:first-child`]: {
        paddingRight: '$12',
      },
      [`${Text}:last-child`]: {
        paddingLeft: '$12',
      },
    },
  },
})
