import React, { ComponentProps } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { ResultsRoot, Stamp, Title, ResultsText, ResultsList } from './Results.styles'
import { BulletedList } from 'components/BulletedList'
import { BulletedListItems } from 'components/BulletedList/BulletedList'

import isotype from '../../../../../../public/assets/isotype-fill.svg'
import Image from 'next/image'
import { Text } from 'components/Text'

const ITEMS: BulletedListItems[] = [
  { name: 'home:results.list.weight', icon: 'weight' },
  { name: 'home:results.list.coat', icon: 'star' },
  { name: 'home:results.list.energy', icon: 'bolt' },
  { name: 'home:results.list.allergies', icon: 'cell' },
  { name: 'home:results.list.digestion', icon: 'smile-beam' },
  { name: 'home:results.list.mealtime', icon: 'heart' },
]

type ResultsOwnProps = ComponentProps<typeof ResultsRoot>
export type ResultsProps = ResultsOwnProps & {}

export const Results = (props: ResultsProps) => {
  const { t } = useTranslation()

  return (
    <ResultsRoot {...props}>
      <Stamp>
        <Image src={isotype} alt="" loading="lazy" />
      </Stamp>
      <Title>
        <Text
          size={{ '@initial': 'h3', '@bp2': 'h2', '@bp3': 'h1' }}
          as="h2"
          weight="bold"
          align="center"
        >
          <span>{t('home:results.title1')}</span>
          <span>{t('home:results.title2')}</span>
        </Text>
      </Title>
      <ResultsText size="headline" weight="regular">
        {t('home:results.text')}
      </ResultsText>
      <ResultsList>
        <BulletedList items={ITEMS} columnCount={2} />
      </ResultsList>
    </ResultsRoot>
  )
}

Results.displayName = 'Results'
