import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Claim } from './ClaimSection.styles'

export const ClaimSection = () => {
  const { t } = useTranslation()

  return (
    <Claim>
      <Text as="h2" size="h1" weight="medium" align={{ '@initial': 'center', '@bp2': 'right' }}>
        {t('home:claim.title')}
      </Text>
    </Claim>
  )
}
