import { Box } from 'components/Box'
import { buttonReset, ButtonRoot } from 'components/Button/Button.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const ReviewsSectionRoot = styled('section', {
  backgroundColor: '$primary-800',
  backgroundSize: '150px',
})

export const ReviewsSectionContent = styled('div', {
  maxWidth: '$max',
  margin: 'auto',
  position: 'relative',
  px: '$3',
  py: '$10',
  color: '$secondary-500',

  [`& ${Stack}`]: {
    alignItems: 'center',
    width: '100%',
  },

  [`& ${Box}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export const ViewerContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$12',
})

export const StampContainer = styled('div', {
  display: 'none',
  $$logoSize: '136px',
  size: '$$logoSize',

  '@bp2': {
    display: 'block',
    position: 'absolute',
    top: 'calc(-$$logoSize / 3)',
    right: 'calc(-$$logoSize / 3)',
  },
})

export const Viewer = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white',
  borderRadius: '44px',
  color: '$primary-800',
  py: '$5',
  px: '$7',

  [`& ${Text}`]: {
    display: 'block',
  },

  '@bp2': {
    width: '625px',
    position: 'relative',

    img: {
      display: 'block',
      $$logoSize: '136px',
      size: '$$logoSize',
      position: 'absolute',
      top: 'calc(-$$logoSize / 3)',
      right: 'calc(-$$logoSize / 3)',
    },
  },
})

export const SelectorsContainer = styled(Stack, {
  justifyContent: 'center',
})

export const Selector = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  size: '$2',
  borderRadius: '$round',
  borderWidth: '1px',
  borderColor: '$secondary-500',
  borderStyle: 'solid',

  variants: {
    active: {
      true: {
        backgroundColor: '$secondary-500',
      },
    },
  },
})

export const ArrowContainer = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'inline-block',

    '& > button': {
      ...buttonReset,
      cursor: 'pointer',
    },
  },
})
