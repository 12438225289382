import { styled } from 'stitches.config'

export const Item = styled('li', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$2',
  color: '$primary-800',
  fontSize: '$headline',
  fontWeight: '$medium',
  lineHeight: '$headline',
  marginBottom: '$2',
  breakInside: 'avoid-column',

  '& span': {
    alignSelf: 'center',
  },

  '@bp1': {
    gap: '$3',
    fontWeight: '$medium',
    marginBottom: '$5',

    '&:last-child': {
      marginBottom: '0',
    },
  },
})

export const BulletedListRoot = styled('ul', {
  listStyleType: 'none',
  columnCount: 1,
})
