import Image from 'next/image'
import { BulletedList, BulletedListItems } from 'components/BulletedList'
import { Stack } from 'components/Stack'
import { useTranslation } from 'hooks/useTranslation'
import {
  PropertiesContentContainer,
  PropertiesImageContainer,
  PropertiesSectionRoot,
} from './PropertiesSection.styles'
import { StampContainer } from '../StampContainer/StampContainer.styles'

import badge from '../../../../../public/assets/badge-rightest.svg'
import benefits1 from '../../../../../public/assets/benefits-1.png'

export const PropertiesSection = () => {
  const { t } = useTranslation()

  const BENEFITS_2: BulletedListItems[] = [
    { name: t('home:properties.real-ingredients'), icon: 'checkbox-circle-line' },
    { name: t('home:properties.minimal-processing'), icon: 'checkbox-circle-line' },
    { name: t('home:properties.vet-approved'), icon: 'checkbox-circle-line' },
  ]

  return (
    <PropertiesSectionRoot>
      <StampContainer>
        <Image src={badge} alt="" />
      </StampContainer>
      <Stack
        direction={{ '@initial': 'column', '@bp2': 'row' }}
        alignItems={{ '@initial': 'center', '@bp2': 'center' }}
        justifyContent="center"
        gap="4"
      >
        <PropertiesImageContainer>
          <Image src={benefits1} alt={t('home:benefits.image')} loading="lazy" />
        </PropertiesImageContainer>
        <PropertiesContentContainer>
          <BulletedList items={BENEFITS_2} iconColor="primary-500" />
        </PropertiesContentContainer>
      </Stack>
    </PropertiesSectionRoot>
  )
}
