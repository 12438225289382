import { Text } from 'components/Text'
import { styled } from 'stitches.config'

export const Stamp = styled('div', {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%,-50%)',

  '@bp2': {
    size: '80px',
    transform: 'translate(-$space$8, $space$8)',
    left: 'auto',
    right: '0',
  },

  '@bp3': {
    size: '112px',
  },
})

export const Title = styled('div', {
  marginBottom: '$2',

  '& span': {
    display: 'block',
  },

  '@bp3': {
    marginBottom: '$3',
  },
})

export const ResultsText = styled(Text, {
  textAlign: 'center',
  marginBottom: '$4',

  '@bp2': {
    maxWidth: '430px',
  },

  '@bp3': {
    maxWidth: '563px',
    marginBottom: '$7',
  },
})

export const ResultsList = styled('div', {
  margin: '0 $4',
})

export const ResultsRoot = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '$primary-800',
  backgroundColor: '$secondary-500',
  padding: '$9 $3 $2',
  maxWidth: '$max',
  margin: 'auto',

  '@bp1': {
    paddingBottom: '$7',
  },
  '@bp2': {
    borderRadius: '99px',
    padding: '$9',
    paddingBottom: '$4',
    margin: '0 $9',
    zIndex: '$1',
  },
})
