import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Results } from './components/Results'
import { Trait, TraitsSection, TraitsSectionContent, TraitsSectionImage } from './Traits.styles'

export const Traits = () => {
  const { t } = useTranslation()

  return (
    <section>
      <TraitsSectionImage>
        <TraitsSectionContent>
          <Results />
        </TraitsSectionContent>
        <TraitsSection>
          <Trait>
            <Text size="h2" as="p" weight="medium">
              {t('home:review.text')}
            </Text>
            <Text size="headline" weight="medium" align="center">
              {t('home:review.author')}
            </Text>
            <Text size="body" weight="medium" align="center">
              {t('home:review.origin')}
            </Text>
          </Trait>
        </TraitsSection>
      </TraitsSectionImage>
    </section>
  )
}
