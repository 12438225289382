import { BulletedListRoot } from 'components/BulletedList/BulletedList.styles'
import { styled } from 'stitches.config'

export const PropertiesContentContainer = styled('div', {
  padding: '0 $3',
  maxWidth: '600px',
})

export const PropertiesImageContainer = styled('div', {
  width: '300px',
  margin: 'auto',

  '@bp2': {
    width: '466px',
    maxWidth: '50%',
  },
})

export const PropertiesSectionRoot = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',

  '@bp2': {
    position: 'relative',
    px: '$8',
  },

  '@bp3': {
    paddingTop: '$2',

    '&': {
      [`${BulletedListRoot}`]: {
        paddingBottom: '$8',
      },
    },
  },
})
