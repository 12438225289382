import { styled } from 'stitches.config'
import { Text } from 'components/Text'

export const Trait = styled(Text, {
  position: 'relative',
  maxWidth: '550px',
  color: '$secondary-500',
  width: '100%',

  '&': {
    [`${Text}`]: {
      display: 'block',
      color: '$secondary-500',

      '&:first-child': {
        marginBottom: '$5',

        '@bp2': {
          fontSize: '$h3',
        },

        '@bp3': {
          fontSize: '$h2',
        },
      },
    },
  },

  '&::before': {
    content: '“',
    position: 'absolute',
    left: '-$15',
    transform: 'translateY(-50%)',
    fontSize: '$h2',
  },

  '&::after': {
    content: '”',
    position: 'absolute',
    right: '-$15',
    transform: 'translateY(-50%)',
    fontSize: '$h2',
  },

  '@bp2': {
    '&::before': {
      left: '-$10',
    },

    '&::after': {
      right: '-$10',
    },
  },

  '@bp3': {
    maxWidth: '894px',
    '&::before': {
      left: '-$15',
    },

    '&::after': {
      right: '-$15',
    },
  },
})

export const TraitsSection = styled('div', {
  display: 'none',
  width: '100%',

  '@bp2': {
    position: 'absolute',
    transform: 'translateY(-50%)',
    display: 'block',
    textAlign: 'center',
    fontSize: '$h2',
    color: '$secondary-500',
    backgroundColor: '$primary-800',
    borderRadius: '75px 75px 0px 0px',
    padding: '390px 0 $8',
  },
})

export const TraitsSectionContent = styled('div', {
  maxWidth: '$max',
  margin: 'auto',
})

export const TraitsSectionImage = styled('div', {
  backgroundImage: 'none',
  position: 'relative',
  paddingTop: '$7',

  '@bp2': {
    backgroundImage: 'url(assets/pattern-home-desktop-02.png)',
    backgroundSize: '210px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    marginBottom: '328px',
    paddingTop: 0,
  },
})
